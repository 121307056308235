<template>
  <div>
    <bread-crumb></bread-crumb>
    <section class="blog-humor">
      <div
        class="blog-humor__descr"
        v-if="data"
        v-html="data.humor_description"
      ></div>

      <h2 class="blog-humor__title" v-if="data">{{ data.humor_title }}</h2>

      <div class="blog-humor__big-card" v-if="data.actual_humor">
        <div class="blog-humor__big-card--img">
          <div class="blog-humor__big-card--wrap">
            <v-lazy-image
              :src="url + data.actual_humor.image"
              rel="preload"
              width="439"
              height="458"
              alt="sdf"
            />
          </div>
        </div>
        <div class="blog-humor__big-card--descr">
          <p
            v-if="data.actual_humor.block_1"
            v-html="
              getTranslatedBlock1(data.actual_humor.translations, $i18n.locale)
            "
          />
          <p
            v-if="data.actual_humor.block_2.length > 11"
            v-html="
              getTranslatedBlock2(data.actual_humor.translations, $i18n.locale)
            "
          />
        </div>
      </div>

      <div class="blog-humor__cards">
        <div class="blog-humor__card" v-for="humor in humors" :key="humor.id">
          <div class="blog-humor__card--img">
            <v-lazy-image
              :src="url + humor.image"
              rel="preload"
              width="435"
              height="238"
              alt="sdf"
            />
          </div>

          <div
            class="blog-humor__card--text"
            v-if="humor.block_1"
            v-html="humor.block_1"
          ></div>
          <div
            class="blog-humor__card--text"
            v-if="humor.block_2.length > 11"
            v-html="humor.block_2"
          ></div>
        </div>

        <button class="blog-humor__link" @click="getMoreHumors()">
          {{ t("load_more") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import VLazyImage from "v-lazy-image";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";
import axios from "axios";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("BlogHumor"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    BreadCrumb,
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      humors: [],
      data: [],
      page: 1,
    };
  },
  methods: {
    getTranslatedBlock1(transArray, locale) {
      let block_1 = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          block_1 = element.block_1;
        }
      });
      return block_1;
    },
    getTranslatedBlock2(transArray, locale) {
      let block_2 = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          block_2 = element.block_2;
        }
      });
      return block_2;
    },
    async getHumors() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/humors",
          config
        );
        this.humors = response.data.data;
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getMoreHumors() {
      try {
        this.page += 1;
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            page: this.page,
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/humors",
          config
        );
        this.humors = [...this.humors, ...response.data.data];
      } catch (e) {
        alert("Ошибка");
      }
    },
    async getBlogPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/blog-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getBlogPage();
    this.getHumors();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.blog-humor {
  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 60, 30);
  }

  &__descr {
    max-width: 800px;
    margin: 0 auto 1em;
    line-height: calc(25 / 16);

    pre {
      @media (max-width: 575.98px) {
        white-space: initial;
      }
    }
  }

  &__big-card {
    display: grid;
    @include property("gap", 105, 20, true, 1670, 1024);
    @include property("margin-bottom", 50, 20);

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1.3fr;
    }

    &--img {
      @media (max-width: 767.98px) {
        max-width: 450px;
        margin: 0 auto;
        width: 100%;
      }
    }

    &--wrap {
      position: relative;
      overflow: hidden;
      padding-bottom: 104%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--descr {
      p {
        @include property("font-size", 24, 20);
        letter-spacing: 0.48px;
        color: #1c1c1c;
        margin-bottom: 0;
        padding: 35px 45px;
        border: 1px solid#f9b80e;

        &:not(:last-child) {
          @include property("margin-bottom", 45, 20);
        }
      }
    }
  }

  &__cards {
    display: grid;
    justify-content: center;
    grid-template-columns: minmax(250px, 435px);
    @include property("column-gap", 105, 20, true, 1670, 1024);
    @include property("row-gap", 50, 25);
    @include property("margin-bottom", 100, 30);

    @media (min-width: 920px) {
      grid-template-columns: repeat(2, minmax(250px, 435px));
    }
  }

  &__card {
    &--img {
      position: relative;
      overflow: hidden;
      padding-bottom: 54.71%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--text {
      padding: 25px 15px;
      @include property("font-size", 24, 20);
      letter-spacing: 0.48px;
      color: #1c1c1c;
      border: 1px solid #f9b80e;
      margin-bottom: 0;

      &:not(:last-child) {
        @include property("margin-bottom", 50, 25);
      }

      pre {
        white-space: pre-line !important;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: flex-end;
    @include property("margin-top", -20, 0);
    @media (min-width: 920px) {
      grid-column: 2 span;
    }
  }

  &__all {
    font-size: 1rem;
    line-height: calc(30 / 16);
    letter-spacing: 2.4px;
    color: #f9b80e;
  }
}
</style>
